import React, { useRef } from "react";
import ModalDialog from "../../common/ModalDialog/index";
import {
  Box,
  Grid,
  List,
  Typography,
  Button,
  Stack,
  CircularProgress,
} from "@mui/material";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import Divider from "@mui/material/Divider";
import OrderTablePDF from "./OrderTablePDF";
import { formatDate } from "../../../utils/date";
import { useLazyQuery } from "@apollo/client";
import {
  EXPORT_ORDER_PDF,
  EMAIL_ORDER_PDF,
} from "../../../graphql/order/query";
import useAuthStore from "../../../store/authStore";
import { useNotification } from "../../../services/hooks/useNotification";

const OrderDetailsDialog = ({ handleClose, defaultValues }) => {
  const { notify } = useNotification();
  const dialogRef = useRef();
  const [exportOrderPDF, { loading, error }] = useLazyQuery(EXPORT_ORDER_PDF);
  const [emailOrderPDF, { loading: emailLoading }] =
    useLazyQuery(EMAIL_ORDER_PDF);
  const { currentStore } = useAuthStore();
  const orderLines = defaultValues?.orderLines;

  const exportAsPDF = async () => {
    try {
      const { data } = await exportOrderPDF({
        variables: {
          id: defaultValues?.id,
        },
        fetchPolicy: "network-only",
      });

      const pdfUrl = data?.exportOrderPdf;

      if (!pdfUrl) {
        notify("Error exporting PDF",
          "error");
      }

      // Create a temporary anchor element and trigger the download
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.setAttribute("download", "order-details.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up
    } catch (error) {
      notify(
        "Failed to download PDF",
         "error",
      );
    } finally {
      handleClose();
    }
  };

  const emailPDF = async () => {
    try {
      const { data } = await emailOrderPDF({
        variables: {
          id: defaultValues?.id,
        },
        fetchPolicy: "network-only",
      });

      if (data?.emailOrderPdf) {
        notify("Email sent successfully", "success");
      } else {
        notify("Failed to send email", "error");
      }
    } catch (error) {
      notify(`Failed to send email: ${error?.message}`, "error");
    } finally {
      handleClose();
    }
  };

  return (
    <ModalDialog open onClose={handleClose} dialogTitle="Purchase Order">
      <Divider />
      <div ref={dialogRef}>
        <Grid container>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            margin={1}
          >
            <Grid item display={"flex"} justifyContent={"flex-end"} xs={2}>
              <LeaderboardIcon color="primary" />
              <Typography variant="label">
                <strong>ORDERFLO</strong>
              </Typography>
            </Grid>

            <Grid item>
              <List
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0",
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    textAlign: "right",
                  }}
                >
                  Order Date :{" "}
                  <span>{formatDate(defaultValues?.orderDate)}</span>
                </Typography>
                <Typography
                  variant="label"
                  sx={{
                    textAlign: "right",
                  }}
                >
                  Purchase Order: <span>{defaultValues?.number}</span>
                </Typography>
              </List>
            </Grid>
          </Grid>
          <Stack
            width={"100%"}
            marginTop={3}
            marginBottom={3}
            padding={{
              xs: 1,
              sm: 1,
            }}
          >
            <Typography variant="caption" color="text.secondary.dark">
              PO NUMBER
            </Typography>
            <Box
              sx={{
                display: "flex",
                border: "1px solid #E5E5E5",
                padding: "5px",
                borderRadius: "10px",
              }}
            >
              <Typography variant="label">{defaultValues?.number}</Typography>
            </Box>
          </Stack>
          <Grid
            item
            xs={12}
            display={"flex"}
            gap={1}
            sx={{
              textAlign: "flex-start",
              marginBottom: "10px",
            }}
          >
            <Grid
              item
              xs={6}
              sx={{
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "10px",
                border: "1px solid #E5E5E5",
              }}
            >
              <Typography variant="label">VENDOR</Typography>
              <List style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="caption">
                  {defaultValues.vendor.name}
                </Typography>
                {/* <Typography variant="caption">OCM#: {defaultValues}</Typography> */}
              </List>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "10px",
                border: "1px solid #E5E5E5",
              }}
            >
              <Typography variant="label">SHIP TO</Typography>
              <List style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="caption">{currentStore?.name}</Typography>
                {/* <Typography variant="caption">{ defaultValues?.store?.address}</Typography> */}
                <Typography variant="caption">
                  BILL TO: {currentStore?.address}
                </Typography>
              </List>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <OrderTablePDF orderLines={orderLines} />
          </Grid>
        </Grid>
        <Stack
          direction="row"
          spacing={{ xs: 1, sm: 1 }}
          useFlexGap
          margin={1}
          sx={{
            backgroundColor: "white",
            borderRadius: "10px",
            border: "1px solid #E5E5E5",
            justifyContent: "space-between",
          }}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Stack
            sx={{
              padding: "10px",
            }}
          >
            <Typography variant="caption">Notes</Typography>
            <Typography variant="label">{defaultValues?.notes}</Typography>
          </Stack>
          <Stack
            sx={{
              padding: "10px",
            }}
          >
            <Typography variant="caption">Total</Typography>
            <Typography variant="label">
              {defaultValues?.totalAmount}
            </Typography>
          </Stack>
        </Stack>
      </div>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "10px",
          gap: "10px",
        }}
      >
        <Button
          endIcon={emailLoading && <CircularProgress size={20} />}
          onClick={emailPDF}
          variant="contained"
          color="primary"
        >
          Email PDF
        </Button>
        <Button
          endIcon={loading && <CircularProgress size={20} />}
          onClick={exportAsPDF}
          variant="contained"
          color="primary"
        >
          Download PDF
        </Button>
      </Box>
    </ModalDialog>
  );
};

export default OrderDetailsDialog;
