import React from "react";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
} from "@mui/material";
import ModalDialog from "../../common/ModalDialog/index";
import Upload from "../../common/Dropzone/index";

function ImportVendors(
    { handleClose, defaultValues }
) {
  return (
    <ModalDialog
      open
      onClose={() => handleClose()}
      dialogTitle="Import Vendors"
    >
      <DialogContent>
        <Upload />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" type="submit">
          Import
        </Button>
      </DialogActions>
    </ModalDialog>
  );
}

export default ImportVendors;
