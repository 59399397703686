import * as React from "react";

import {
  Box,
  CircularProgress,
  Tooltip,
  Chip,
  SvgIcon,
  TextField,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import IconButton from "@mui/material/IconButton";
import EnhancedTable from "../../common/Table/EnhancedTable";
import PagePlaceholder from "../../common/PagePlaceholder.jsx/PagePlaceholder";
import tableCss from "../../common/Table/styles.module.css";
import FullPageSpinner from "../FullPage";

const generateColumns = (list, columns) => {
  return columns.reduce((acc, column) => {
    acc[column.id] = {
      rawValue: column.render
        ? column.render(list[column.id])
        : list[column.id],
      content: (
        <Box component="span" sx={{}}>
          {column.render ? (
            column.render(list[column.id])
          ) : (
            isNewItem(list.createdAt) && column.id === 'name' ? (
              <>
                {list[column.id]}
                <Chip
                  label="New"
                  sx={{
                    ml: 1,
                    backgroundColor: "green",
                  }}
                />
              </>
            ) : (
              list[column.id]
            )
          )}
        </Box>
      ),
    };
    return acc;
  }, {});
};
const isNewItem = (createdAt) => {
  const now = new Date();
  const createdDate = new Date(Number(createdAt));

  const diffTime = Math.abs(now - createdDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays <= 7; // Consider items created within the last 7 days as new
};

function Lists({ handleViewDetail, isLoading, list, columns }) {
  const headCells = [...columns, { id: "actions", label: "" }];

  if (isLoading) return <FullPageSpinner />;

  const rows = list?.map((item) => ({
    cells: {
      ...generateColumns(item, columns),
      actions: {
        rawValue: "Actions",
        content: (
          <div className={tableCss.actions}>
            <Tooltip title="View Details" placement="top">
              <IconButton onClick={() => handleViewDetail(item)} size="small">
                <SvgIcon
                  component={LaunchIcon}
                  inheritViewBox
                  sx={{ color: "icon.main" }}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
    },
  }));

  return (
    <main>
      {list?.length > 0 && rows.length > 0 ? (
        <EnhancedTable rows={rows} headCells={headCells} mobileVariant />
      ) : (
        <Box bgcolor="background.paper" borderRadius={1}>
          <PagePlaceholder text="No entries found" />
        </Box>
      )}
    </main>
  );
}

export default React.memo(Lists);
