import { Box, UploadFileIcon } from "@mui/material";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

const Upload = () => {
  const [reading, setReading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [file, setFile] = React.useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => setError("file reading was aborted");
      reader.onerror = (error) => setError(error);
      reader.onload = () => {
        // Do whatever you want with the file contents
        setFile(acceptedFiles[0].name);
        const binaryStr = reader.result;
      };
      //   reader.readAsArrayBuffer(file);
      reader.readAsText(file);
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
      "text/plain": [".txt"],
      "text/json": [".json"],
    },
  });
  return (
    <section>
      <div {...getRootProps()}>
        <input {...getInputProps()} />

        {!file ? (
          <Box
            border={"1px dashed #ccc"}
            p={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <h6>Drag 'n' drop some files here, or click to select files</h6>
            <h4>(Only *.json and *.csv files will be accepted)</h4>
          </Box>
        ) : (
          <h5>{file}</h5>
        )}
      </div>
    </section>
  );
};

export default Upload;
